<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/Decaissement'">
          Décaissement>
        </router-link>
        <span>Ordres de virement</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des ordres de virement
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn my-5"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div>
    </div>
    <div class="inner_container">
      <div class="tab_holder">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <!-- Table header Filter-->
              <th scope="col"
                  class="text-left th-blue">
                Numéro    
              </th>
              <!-- Table header Filter-->
              <th scope="col"
                  class="text-left th-blue">
                Email
              </th>
              <!-- Table header Filter-->
              <th scope="col"
                  class="text-left th-blue">
                Nom
              </th>
              <!-- Table header Filter-->
              <th scope="col"
                  class="text-left th-blue">
                Montant (FCFA)
              </th>
              <th scope="col"
                  class="text-left th-blue">
                Nombre de décaissements
              </th>
              <th scope="col"
                  class="text-left th-blue">
                Date
              </th>
              <th scope="col"
                  class="text-left th-blue">Détails</th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td class="text-center"
                  colspan="7">Chargement ...</td>
            </tr>
          </tbody>
          <tbody v-else-if="long != 0">
            <tr v-for="(virement, key) in virements.donnees"
                :key="key">
              <td>
                <a class="action-link"
                   @click="launchUpdate(virement.id)">{{ virement.id }} </a>
              </td>
              <td>{{ virement.auteur.email }}</td>
              <td>{{ virement.auteur.nom }}</td>
              <td class="text-right">{{ Math.round(virement.montant_ordre_virement).toLocaleString() }}</td>
              <td class="text-right">{{ Math.round(virement.nombre_ordre_virement).toLocaleString() }}</td>
              <td>{{ virement.date }}</td>
              <td><a v-bind:href="baseUrl + virement.url"
                     :download="'ordre_virement' + virement.id">Télécharger</a></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center"
                  colspan="7">
                Aucun ordre de virement enregistré
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row pagination">
          <div v-if="virements.pagination"
               class="col-md-1 mx-auto text-center">
            <span v-if="virements.pagination.precedent"
                  class="badge badge-pill badge-info"
                  @click="pIndex--, paging(virements.pagination.precedent)"> &#60; </span>
            {{ pIndex }}
            <span v-if="virements.pagination.suivant"
                  class="badge badge-pill badge-info"
                  @click="pIndex++, paging(virements.pagination.suivant)"> &#62; </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.48em;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex"
import ListFilter from "@/components/shared/Filter"

export default {
  name: "Virements",
  components:{
    ListFilter,
  },
  data: () => ({
    programmeData: {
      libelle: "",
      order: "asc",
      tri: ""
    },
    long: "",
    pIndex:1,
    filtre:[],
    chmp:[
      {
        valeur:"id",
        type:"numeric",
        table:"ordre_virements",
        libelle:"Numéro",
      },
      {
        valeur:"email",
        type:"caractere",
        table:"utilisateurs",
        libelle:"Email",
      },
      {
        valeur:"nom",
        type:"caractere",
        table:"utilisateurs",
        libelle:"Nom",
      },
      {
        valeur:"created_at",
        type:"date",
        table:"ordre_virements",
        libelle:"Date",
      },
    ],
    loaded: false,
    baseUrl: process.env.VUE_APP_UPLOAD
  }),
  created() {
    this.Virements()
  },
  watch: {
    virements() {
      if (this.virements.donnees) {
        this.loaded = true
        this.long = this.virements.donnees.length
      }
    }
  },
  computed: {
    ...mapGetters(["virements", "virementsErrors"])
  },
  methods: {
    ...mapActions(["Virements", "pageVirements"]),
    onFormChange() {
      this.form.validate()
    },
    launchUpdate(id) {
      this.$router.push({ name: "DVirement", params: { id: id } })
    },
    filtrer() {
      this.loaded = false
      this.Virements(this.filtre)
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageVirements(url)
    }
  }
}
</script>
